// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dermatology-js": () => import("./../../../src/pages/dermatology.js" /* webpackChunkName: "component---src-pages-dermatology-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-publicnotice-js": () => import("./../../../src/pages/publicnotice.js" /* webpackChunkName: "component---src-pages-publicnotice-js" */),
  "component---src-pages-specifiedcommercialtransactionslaw-js": () => import("./../../../src/pages/specifiedcommercialtransactionslaw.js" /* webpackChunkName: "component---src-pages-specifiedcommercialtransactionslaw-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-manual-article-js": () => import("./../../../src/templates/manual-article.js" /* webpackChunkName: "component---src-templates-manual-article-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

